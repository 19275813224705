import React from "react";
import Header from "./header";
import Footer from "./footer";
import ReactGA from "react-ga4";
ReactGA.initialize(String(process.env.REACT_APP_PUBLIC_GA_ID));
const Layout = ({ children }: any) => {
  React.useEffect(() => {
    ReactGA.send({
      hitType: window.location.pathname,
      page: window.location.pathname,
    });
  }, [window.location.pathname]);
  return (
    <div className="w-full gradient-a">
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
