import React from "react";
import { BrowserRouter } from "react-router-dom";
import CustomRoutes from "./routes";
import "./App.css";
import { ScrollContextProvider } from "./components/context/scrollContext";

function App() {
  return (
    <BrowserRouter>
      <ScrollContextProvider>
        <CustomRoutes />
      </ScrollContextProvider>
    </BrowserRouter>
  );
}

export default App;
