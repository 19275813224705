import React, { lazy, Suspense, Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Layout from "./layout";
import { AnimatePresence } from "framer-motion";

const routesData = [
  {
    path: "/",
    component: lazy(() => import("./components/Home/Home")),
    layout: Layout,
  },
  // {
  //   path: "/home",
  //   component: lazy(() => import("./components/Home/Home")),
  //   layout: Layout,
  // },
  {
    path: "*",
    component: lazy(() => import("./Common/404Page")),
  },
  {
    path: "/about",
    layout: Layout,
    component: lazy(() => import("./components/About/About")),
  },
  {
    path: "/faqs",
    layout: Layout,
    component: lazy(() => import("./components/Faqs/Faqs")),
  },
  {
    path: "/app-guide",
    layout: Layout,
    component: lazy(() => import("./components/AppGuide/AppGuide")),
  },
];

const renderRoutes = (routesData: any) =>
  routesData ? (
    <Suspense>
      <Routes>
        {routesData.map((item: any) => {
          const Layout = item?.layout || Fragment;
          const Component = item?.component;
          return (
            <Route
              key={item.component}
              path={item.path}
              element={
                <AnimatePresence mode="wait">
                  <Layout>
                    <Component />
                  </Layout>
                </AnimatePresence>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  ) : null;
function CustomRoutes() {
  return renderRoutes(routesData);
}

export default CustomRoutes;
