import React, { useEffect } from "react";
import { logoIcon } from "../assets/svg";
import { Link, useLocation } from "react-router-dom";
const Header = () => {
  const isMediumScreen = () => {
    return window.innerWidth > 768;
  };
  const [expandNavbar, setExpandNavbar] = React.useState(false);
  const location = useLocation();
  useEffect(() => {
    const handleResize = () => {
      setExpandNavbar(!isMediumScreen() ? false : expandNavbar);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <nav className="">
      <div className="max-w-full flex flex-wrap items-center justify-between mx-auto px-6 py-4">
        <Link to="/" className="flex items-center">
          <img
            src={logoIcon}
            className="w-[50px] md:w-[68px] lg:w-[100px] h-[43px] md:h-[60px] lg:h-[92px] mr-3"
            alt="Flowbite Logo"
          />
          <span className="self-center text-textGray text-f20 sm:text-f24 md:text-f30  lg:text-[47px] font-black  whitespace-nowrap">
            EXA AI
          </span>
        </Link>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden focus:outline-none focus:ring-2 "
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={() => setExpandNavbar(!expandNavbar)}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_34_471)">
              <path
                d="M7.75234 6.20039C7.75234 5.34438 8.44633 4.65039 9.30234 4.65039H29.4523C30.3084 4.65039 31.0023 5.34438 31.0023 6.20039C31.0023 7.0564 30.3084 7.75039 29.4523 7.75039H9.30234C8.44633 7.75039 7.75234 7.05634 7.75234 6.20039ZM29.4523 13.9504H5.00313C4.14711 13.9504 3.45312 14.6444 3.45312 15.5004C3.45312 16.3564 4.14711 17.0504 5.00313 17.0504H29.4523C30.3084 17.0504 31.0023 16.3564 31.0023 15.5004C31.0023 14.6444 30.3084 13.9504 29.4523 13.9504ZM29.4523 23.2504H15.5023C14.6464 23.2504 13.9523 23.9444 13.9523 24.8004C13.9523 25.6563 14.6464 26.3504 15.5023 26.3504H29.4523C30.3084 26.3504 31.0023 25.6563 31.0023 24.8004C31.0023 23.9444 30.3084 23.2504 29.4523 23.2504Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_34_471">
                <rect width="31" height="31" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <div
          className={
            expandNavbar
              ? "fixed top-0 bottom-0 left-0 right-0 p-12 block z-50 backdrop-blur-[2px]"
              : "hidden md:block"
          }
          onClick={() => setExpandNavbar(false)}
        >
          <div
            className={`${
              expandNavbar ? "bg-white rounded-[10px]" : ""
            }   w-full  md:w-auto" id="navbar-default`}
          >
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4   rounded-lg  md:flex-row  md:mt-0  ">
              <li>
                <Link
                  to={"/"}
                  className={`${
                    expandNavbar && location.pathname === "/"
                      ? "gradient-c text-white rounded-[5px] "
                      : ""
                  } block py-2  text-primary font-semibold px-4 text-f18 rounded md:bg-transparent `}
                  aria-current="page"
                >
                  Home
                  {location.pathname === "/" && !expandNavbar && (
                    <hr className="border-2 rounded-[2px]" />
                  )}
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className={`${
                    expandNavbar && location.pathname === "/about"
                      ? "gradient-c text-white rounded-[5px] "
                      : ""
                  } block py-2  text-primary font-semibold px-4 text-f18 rounded md:bg-transparent `}
                  aria-current="page"
                >
                  About
                  {location.pathname === "/about" && !expandNavbar && (
                    <hr className="border-2 rounded-[2px]" />
                  )}
                </Link>
              </li>
              <li>
                <Link
                  to="/app-guide"
                  className={`${
                    expandNavbar && location.pathname === "/app-guide"
                      ? "gradient-c text-white rounded-[5px] "
                      : ""
                  } block py-2  text-primary font-semibold px-4 text-f18 rounded md:bg-transparent `}
                  aria-current="page"
                >
                  App Guide
                  {location.pathname === "/app-guide" && !expandNavbar && (
                    <hr className="border-2 rounded-[2px]" />
                  )}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
