import React from "react";
import { smallLogoIcon } from "../assets/svg";
import { Link } from "react-router-dom";
import { useScrollContext } from "../components/context/scrollContext";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const { setScrollToSection } = useScrollContext();
  const handleCategoryClick = (sectionName: any) => {
    setScrollToSection(sectionName);
    navigate("/");
  };
  return (
    <footer className="bg-gradient-to-r from-black via-black to-black h-full w-full text-white px-5 py-10">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className="grid grid-cols-4 gap-6 px-10">
          <div className=" col-span-4 md:col-span-2 lg:col-span-1 space-y-4 flex flex-col items-center justify-center text-center lg:block lg:text-start">
            <a href="/" className="flex items-center">
              <img
                src={smallLogoIcon}
                className="w-[50px] h-[46px] mr-2"
                alt="Flowbite Logo"
              />
              <span className="self-center text-f32 font-bold whitespace-nowrap">
                EXA AI Chat
              </span>
            </a>
            <p className="text-f14 font-normal">
              Experience Next-Level Conversations with our Enhanced
              ChatGPT-powered App
            </p>
            <div className="flex items-center space-x-4">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/Exaaichat"
              >
                <svg
                  width="31"
                  height="26"
                  viewBox="0 0 31 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M30.7544 3.75065C29.6315 4.26107 28.4211 4.59648 27.1669 4.7569C28.4503 3.98398 29.4419 2.75898 29.9086 1.28607C28.6982 2.01523 27.3565 2.52565 25.9419 2.81732C24.7898 1.56315 23.1711 0.833984 21.3336 0.833984C17.9065 0.833984 15.1065 3.63398 15.1065 7.09023C15.1065 7.58607 15.1648 8.06732 15.2669 8.5194C10.0753 8.2569 5.45234 5.76315 2.37526 1.98607C1.83568 2.90482 1.52943 3.98398 1.52943 5.12148C1.52943 7.2944 2.62318 9.2194 4.31484 10.3132C3.27943 10.3132 2.31693 10.0215 1.47109 9.58398V9.62773C1.47109 12.6611 3.62943 15.1986 6.48776 15.7673C5.57007 16.0185 4.60665 16.0534 3.67318 15.8694C4.06927 17.1126 4.845 18.2004 5.89133 18.9799C6.93765 19.7594 8.20195 20.1915 9.50651 20.2152C7.29514 21.9659 4.55401 22.9122 1.73359 22.8986C1.23776 22.8986 0.741927 22.8694 0.246094 22.8111C3.01693 24.5902 6.31276 25.6257 9.84193 25.6257C21.3336 25.6257 27.6482 16.0882 27.6482 7.8194C27.6482 7.54232 27.6482 7.27982 27.6336 7.00273C28.8586 6.12773 29.9086 5.0194 30.7544 3.75065Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/exaaichat"
              >
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.3333 0.375C24.1069 0.375 24.8487 0.682291 25.3957 1.22927C25.9427 1.77625 26.25 2.51812 26.25 3.29167V23.7083C26.25 24.4819 25.9427 25.2237 25.3957 25.7707C24.8487 26.3177 24.1069 26.625 23.3333 26.625H2.91667C2.14312 26.625 1.40125 26.3177 0.854272 25.7707C0.307291 25.2237 0 24.4819 0 23.7083V3.29167C0 2.51812 0.307291 1.77625 0.854272 1.22927C1.40125 0.682291 2.14312 0.375 2.91667 0.375H23.3333ZM22.6042 22.9792V15.25C22.6042 13.9891 22.1033 12.7799 21.2117 11.8883C20.3201 10.9967 19.1109 10.4958 17.85 10.4958C16.6104 10.4958 15.1667 11.2542 14.4667 12.3917V10.7729H10.3979V22.9792H14.4667V15.7896C14.4667 14.6667 15.3708 13.7479 16.4938 13.7479C17.0352 13.7479 17.5545 13.963 17.9374 14.3459C18.3203 14.7288 18.5354 15.2481 18.5354 15.7896V22.9792H22.6042ZM5.65833 8.48333C6.30811 8.48333 6.93128 8.22521 7.39075 7.76575C7.85021 7.30628 8.10833 6.68311 8.10833 6.03333C8.10833 4.67708 7.01458 3.56875 5.65833 3.56875C5.00469 3.56875 4.37781 3.82841 3.91561 4.29061C3.45341 4.75281 3.19375 5.37969 3.19375 6.03333C3.19375 7.38958 4.30208 8.48333 5.65833 8.48333ZM7.68542 22.9792V10.7729H3.64583V22.9792H7.68542Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/EXAAIChat"
              >
                <svg
                  width="31"
                  height="31"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  fill="none"
                  y="0px"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12,2C6.477,2,2,6.477,2,12c0,5.013,3.693,9.153,8.505,9.876V14.65H8.031v-2.629h2.474v-1.749 c0-2.896,1.411-4.167,3.818-4.167c1.153,0,1.762,0.085,2.051,0.124v2.294h-1.642c-1.022,0-1.379,0.969-1.379,2.061v1.437h2.995 l-0.406,2.629h-2.588v7.247C18.235,21.236,22,17.062,22,12C22,6.477,17.523,2,12,2z"
                    fill="white"
                  ></path>
                </svg>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.tiktok.com/@exaaichat"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="31"
                  height="31"
                  viewBox="0 0 50 50"
                >
                  <path
                    d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/exaaichat/"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="31"
                  height="31"
                  viewBox="0 0 50 50"
                >
                  <path
                    d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/@EXAAIChat"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="35"
                  height="35"
                  viewBox="0 0 50 50"
                >
                  <path
                    d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className=" col-span-2 lg:col-span-1 space-y-3 mx-auto lg:ml-auto">
            <h2 className="text-f24 font-bold">Features</h2>
            <ul className="text-f16 font-normal space-y-3">
              <li>
                <div
                  className=" cursor-pointer"
                  onClick={() => handleCategoryClick("exploring-categories")}
                >
                  Exploring GPTs
                </div>
              </li>
              <li>
                <div
                  className="cursor-pointer"
                  onClick={() => handleCategoryClick("boardroom")}
                >
                  Boardroom
                </div>
              </li>
              <li>
                <div
                  className="cursor-pointer"
                  onClick={() => handleCategoryClick("communities")}
                >
                  Communities
                </div>
              </li>
            </ul>
          </div>
          <div className=" col-span-2 lg:col-span-1  space-y-3 mx-auto lg:ml-auto">
            <h2 className="text-f24 font-bold">About</h2>
            <ul className="text-f16 font-normal space-y-3">
              <li>
                <a href="/about" className="hover:underline ">
                  About Us
                </a>
              </li>
            </ul>
          </div>
          <div className=" col-span-4 md:col-span-2 lg:col-span-1 space-y-3 lg:ml-auto flex flex-col items-center justify-center text-center lg:block lg:text-start">
            <h2 className="text-f24 font-bold">Support</h2>
            <ul className="text-f16 font-normal space-y-3">
              <li>
                <a href="/faqs" className="hover:underline">
                  FAQs
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="pt-8 px-5">
          <hr className="border-1 border-white" />
          <div className="text-center pt-6 flex justify-center space-x-4">
            <h1>© EXA AI Chat 2023 - All Rights Reserved</h1>
            <h1>|</h1>
            <a
              href="https://static.exaai.chat/public/privacy.html"
              className="hover:underline"
            >
              Privacy
            </a>
            <h1>|</h1>
            <a
              href="https://static.exaai.chat/public/tos.html"
              className="hover:underline "
            >
              Terms
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
