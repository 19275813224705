import { createContext, useContext, useState } from "react";

// Define the context type
type ScrollContextType = {
  scrollToSection: string; // Adjust the type based on your needs
  setScrollToSection: React.Dispatch<React.SetStateAction<string>>;
};

const ScrollContext = createContext<ScrollContextType | null>(null);

export const useScrollContext = () => {
  const context = useContext(ScrollContext);
  if (context === null) {
    throw new Error(
      "useScrollContext must be used within a ScrollContextProvider"
    );
  }
  return context;
};

export const ScrollContextProvider = ({ children }: any) => {
  const [scrollToSection, setScrollToSection] = useState("");

  return (
    <ScrollContext.Provider value={{ scrollToSection, setScrollToSection }}>
      {children}
    </ScrollContext.Provider>
  );
};
